// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';

const HomePage = lazy(() => import('./Pages/HomePage/HomePage'));
const FeaturesPage = lazy(() => import('./Pages/FeaturesPage/FeaturesPage'));
const HiringPage = lazy(() => import('./Pages/HiringPage/HiringPage'));
const DesktopNavbar = lazy(() => import('./Components/DesktopNavbar/DesktopNavbar'));
const Footer = lazy(() => import('./Components/Footer/Footer'));
const PolicyPage = lazy(() => import('./Pages/PolicyPage/PolicyPage'));
const TermServices = lazy(() => import('./Pages/Terms&Services/TermServices'));
const PricingPage = lazy(() => import('./Pages/PricingPage/PricingPage'));
const AboutPage = lazy(() => import('./Pages/AboutPage/AboutPage'));
const FAQsPage = lazy(() => import('./Pages/FAQsPage/FAQsPage'));
const PeopleManagement = lazy(() => import('./Pages/Solution/PeopleManagement'));
const TimeAttendance = lazy(() => import('./Pages/Solution/TimeAttendance'));
const LeaveManagement = lazy(() => import('./Pages/Solution/LeaveManagement'));
const PayrollManagement = lazy(() => import('./Pages/Solution/PayrollManagement'));
const RemoteLocationManagement = lazy(() => import('./Pages/Solution/RemoteLocationManagement'));
const Blogs = lazy(() => import('./Pages/Blogs/Blogs'));
const SplashScreen = lazy(() => import('./Pages/SplashScreen/SplashScreen'));
const BlogDetails = lazy(() => import('./Pages/Blogs/BlogsDetailPage'));

function App() {
  return (
    <Router>
      <Suspense fallback={<SplashScreen />}>
        <DesktopNavbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:slug" element={<BlogDetails />} />
          <Route path="/faqs" element={<FAQsPage />} />
          <Route path="/hiring" element={<HiringPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/privacypolicy" element={<PolicyPage />} />
          <Route path="/termsandCondition" element={<TermServices />} />
          <Route path="/people-management" element={<PeopleManagement />} />
          <Route path="/time-attendance" element={<TimeAttendance />} />
          <Route path="/leave-management" element={<LeaveManagement />} />
          <Route path="/payroll-management" element={<PayrollManagement />} />
          <Route path="/remote-location-management" element={<RemoteLocationManagement />} />
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;